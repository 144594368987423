import React, { useState } from "react"
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper/core"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import { CardStructure } from "../../webinar/central-page-cards"
import SVGIcon from "../SVGIcon"
import "./swiper-carousel-card.scss"

SwiperCore.use([EffectCoverflow, Autoplay])

export default function SwiperCarouselCards(props) {
  const [swipe, setSwipe] = useState()
  const [currentTestimonial, setCurrentTestimonial] = useState(0)
  const responsive = {
    992: {
      slidesPerView: 2,
    },
    734: {
      slidesPerView: 1,
    },
    200: {
      slidesPerView: 1,
    },
  }
  const onchange = data => {
    setCurrentTestimonial(data)
  }

  const CustomButtonGroup = () => {
    return (
      <>
        <button
          className="carousel-btn carousel-btn--prev position-absolute"
          onClick={() => {
            swipe.slidePrev()
          }}
        >
          <SVGIcon name="arrowLeftNew" className="carousel-btn-arrow--left arrow btn-secondary-new p-0" />
        </button>
        <p className="swiper-pagination position-absolute fw-600 text-deep-purple">{currentTestimonial+1}/{props.cards.length}</p>
        <button
          className="carousel-btn carousel-btn--next position-absolute"
          onClick={() => {
            swipe.slideNext()
          }}
        >
          <SVGIcon name="arrowRightNew" className="carousel-btn-arrow--right arrow  btn-secondary-new p-0" />
        </button>
      </>
    )
  }

  return (
    <>
      <div className="caro-container">
        <CustomButtonGroup />
        <div className="swiper-container">
          <Swiper
            effect={"fade"}
            speed={750}
            spaceBetween={30}
            slidesPerView={"auto"}
            onBeforeInit={swipper => setSwipe(swipper)}
            breakpoints={responsive}
            className="mySwiper"
            onSlideChange={data => {
              onchange(data.realIndex)
            }}
          >
            {props.cards.map((item, i) => (
              <SwiperSlide>
                <CardStructure
                  item={item}
                  pageName={props.pageName}
                  togglePopup={props.togglePopup}
                  buttonText={
                    props.pageName === "Webinars page"
                      ? "register"
                      : "learn more"
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  )
}
