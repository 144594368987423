import { css } from "@emotion/react"
import { graphql, StaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import CTA from "../components/common/CTA"
import Frame from "../components/common/frame"
import PopUpForm from "../components/common/PopUpForm"
import SwiperCarouselCards from "../components/common/Swiper-Carousel/swiper-carousel-cards"
import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import {
  ShowAllCards
} from "../components/webinar/central-page-cards"
import EventTabs from "../components/webinar/event-tabs"
import WebinarDropDown from "../components/webinar/webinar-dropdown"
import "../styles/pages/central-page.scss"

function CentralPage({ pageContext }) {
  const { page } = pageContext
  const [currentCategory, setCurrentCategory] = useState(
    page.pageName === "Webinars page" ? "all" : "SuperOps Events"
  )
  const [currentMainCategory, setCurrentMainCategory] = useState("")
  let navBgColor = page.hero.backgroundColor
  navBgColor = navBgColor.slice(
    navBgColor.indexOf("#"),
    navBgColor.indexOf("#") + 7
  )

  const [eventName, setEventName] = useState("")
  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = name => {
    setEventName(name)
    setPopupVisibility(!popupVisibility)
  }

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      required: false,
      errorMessage: "Please enter company",
    },
    {
      name: "phone",
      type: "phone",
      label: "Mobile number",
      required: false,
      errorMessage: "Please enter a mobile number",
    },
  ]

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1.96,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 1.2,
    },
    mobile: {
      breakpoint: { max: 767, min: 500 },
      items: 1.2,
    },
    mobile1: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  }
  return (
    <>
      <StaticQuery
        query={graphql`
          query webinarAndEvents {
            SuperOps {
              tags(where: { pageName: Webinar }, orderBy: order_ASC) {
                name
                slug
              }
              webinars (first: 250){
                pageName
                category
                mainCategory
                slug
                heroHeading
                videoLink
                date
                time
                name
                order
                coverImage {
                  url
                }
              }
            }
          }
        `}
        render={data => {
          const allWebinars = data.SuperOps.webinars
            .filter(x => x.pageName === "Webinar")
            .reverse()
          const allEvents = data.SuperOps.webinars.filter(
            x => x.pageName === "Events"
          )

          return (
            <div className="central-page">
              <Frame seo={page.seo}>
                <header>
                  <Navigation page={"Home"} color={navBgColor} newButton />
                </header>

                <div>
                  <PopUpForm
                    visibility={popupVisibility}
                    togglePopup={val => {
                      setPopupVisibility(val)
                    }}
                    formData={formdata}
                    endpoint={process.env.HUBSPOT_EVENTS_CENTRAL_ENDPOINT}
                    url={process.env.HUBSPOT_EVENTS_CENTRAL_URL}
                    formName="Events central - Superops"
                    successMsg="Thank you!"
                    additionalFormProps={{ IpStackData: true }}
                    extradata={{ name: "events_name", value: eventName }}
                  />
                </div>

                <div
                  css={css`
                    background: ${page.hero.backgroundColor};
                  `}
                >
                  <section className="hero pt80">
                    <Container className="Layout-container text-center">
                      <h1 className="font-roboto fw-800 heading mx-auto mb30 down-to-up-1">
                        {page.hero.heading.text}
                      </h1>
                      <p className="fw-500 p18 description mx-auto down-to-up-2">
                        {page.hero.content.text}
                      </p>
                    </Container>
                  </section>

                  <section
                    className={`${
                      page.pageName === "Webinars page" ? "webinars" : "events"
                    }`}
                  >
                    {page.newfield.map((field, ind) => {
                      switch (field.__typename) {
                        case "SuperOps_Card": {
                          if (field.cardName === "Upcoming") {
                            const upcomingWebinars =
                              page.pageName === "Webinars page"
                                ? allWebinars.filter(
                                    x => new Date(x.date) >= new Date()
                                  )
                                : allEvents
                                    .filter(x => x.order)
                                    .sort((a, b) => a.order - b.order)

                            let hideCarouselArr = false
                            if (screenWidth < 992) {
                              if (upcomingWebinars.length < 2)
                                hideCarouselArr = true
                            } else {
                              if (upcomingWebinars.length < 3)
                                hideCarouselArr = true
                            }
                            return (
                              <>
                                {upcomingWebinars.length ? (
                                  <section
                                    className={`down-to-up-3 upcoming-webinars mt60 mx-auto position-relative ${
                                      hideCarouselArr
                                        ? "hide-carousel-arrow"
                                        : ""
                                    }`}
                                  >
                                    <Container className="Layout-container position-relative container-box p-0">
                                      <Row className="inside-wrapper position-relative mx-auto">
                                        <Col
                                          lg={4}
                                          className="left-col position-relative"
                                        >
                                          <Col lg={9}>
                                            <div className="altv3">
                                              <p className="p24 font-roboto text-pink fw-600 mb24">
                                                {field.text[0]}
                                              </p>
                                              <p className="p16 description">
                                                {field.text[1]}
                                              </p>
                                            </div>
                                          </Col>
                                        </Col>
                                        <Col lg={8} className="right-col">
                                          <SwiperCarouselCards
                                            cards={upcomingWebinars}
                                            pageName={page.pageName}
                                            togglePopup={name =>
                                              togglePopup(name)
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Container>
                                  </section>
                                ) : null}
                              </>
                            )
                          } else {
                            return (
                              <section
                                className={`${
                                  page.pageName === "Webinars page"
                                    ? "vertical-dropdowns"
                                    : "horizontal-tabs"
                                } down-to-up-5 on-demand-webinars mx-auto`}
                              >
                                <Container className={`container-box`}>
                                  <div className="inside-wrapper">
                                    {field.text[0] ? (
                                      <p className="p24 font-roboto text-pink fw-600 mb50 pt50">
                                        {field.text[0]}
                                      </p>
                                    ) : null}
                                    <div className="row d-flex flex-wrap justify-content-between">
                                      <Col
                                        lg={
                                          page.pageName === "Webinars page"
                                            ? 3
                                            : 12
                                        }
                                        className={`left track-category ${page.pageName ===
                                          "Webinars page" && "sticky-top"}`}
                                      >
                                        <div
                                          className={`${page.pageName ===
                                            "Webinars page" && "sticky-top"}`}
                                        >
                                          {page.pageName === "Webinars page" ? (
                                            <WebinarDropDown
                                              currentTag={currentCategory}
                                              selectTag={category =>
                                                setCurrentCategory(category)
                                              }
                                              mainCategory={main =>
                                                setCurrentMainCategory(main)
                                              }
                                              allTags={data.SuperOps.tags}
                                            />
                                          ) : (
                                            <EventTabs
                                              currentTag={currentCategory}
                                              selectTag={category =>
                                                setCurrentCategory(category)
                                              }
                                            />
                                          )}
                                        </div>
                                      </Col>
                                      <Col
                                        lg={
                                          page.pageName === "Webinars page"
                                            ? 8
                                            : 12
                                        }
                                        className="right"
                                      >
                                        <Row className="webinar-card-wrapper flex-wrap">
                                          <ShowAllCards
                                            category={currentCategory}
                                            allCards={
                                              page.pageName === "Webinars page"
                                                ? allWebinars.filter(
                                                    x => x.videoLink
                                                  )
                                                : allEvents
                                            }
                                            pageName={page.pageName}
                                            togglePopup={name =>
                                              togglePopup(name)
                                            }
                                            currentMainCategory={
                                              currentMainCategory
                                            }
                                            colLg={
                                              page.pageName === "Webinars page"
                                                ? 6
                                                : 4
                                            }
                                            buttonText={
                                              page.pageName === "Webinars page"
                                                ? "access"
                                                : "learn more"
                                            }
                                          />
                                        </Row>
                                      </Col>
                                    </div>
                                  </div>
                                </Container>
                              </section>
                            )
                          }
                        }
                        case "SuperOps_CtaFeature": {
                          return (
                            <section className="mt80">
                              <Slide delay="200">
                                <CTA
                                  data={[field.ctaBox]}
                                  className="Layout-container"
                                  lgLeft={7}
                                  lgRight={4}
                                  rightStyles={{ alignItems: "center" }}
                                  newButton
                                  newDesign
                                />
                              </Slide>
                            </section>
                          )
                        }
                      }
                    })}
                  </section>
                </div>
              </Frame>
            </div>
          )
        }}
      />
    </>
  )
}

export default CentralPage