import { Link } from "gatsby"
import React from "react"
import { Col } from "react-bootstrap"
import SVGIcon from "../common/SVGIcon"
import CheckLinkDomain from "../utility/current_domain"

let ShowAllCards = props => {
  const { category, allCards, togglePopup, pageName, colLg, buttonText } = props

  return allCards.map(item => {
    const { mainCategory } = item

    if (
      props.showAll ||
      category === "all" ||
      (category !== "Others" && category === item.category
        ? true
        : props.currentMainCategory && category === item.category
        ? props.currentMainCategory === mainCategory
        : false)
    ) {
      return (
        <Col lg={colLg ? colLg : 6}>
          <CardStructure
            item={item}
            togglePopup={togglePopup}
            pageName={pageName}
            buttonText={buttonText}
          />
        </Col>
      )
    }
  })
}

const CardStructure = ({ item, togglePopup, pageName, buttonText }) => {
  const { mainCategory, coverImage, heroHeading, date, time, slug, name } = item

  return (
    <div className="webinar-card br10">
      <div>
        <img src={coverImage.url} className="w-100 image" />
      </div>
      <div className="texts">
        {mainCategory && <p className="text-pink tag">{mainCategory}</p>}
        <p className="font-roboto fw-bold p20 heading mb24">{heroHeading}</p>
        <div className="d-flex align-items-center gap-8 mb20">
          <SVGIcon name="webinar/calendar" />
          <p className="fw-500 m-0 p14">{date}</p>
        </div>
        <div className="d-flex align-items-center gap-8">
          <SVGIcon
            name={`webinar/${
              pageName === "Webinars page" ? "clock" : "location"
            }`}
          />
          <p className="fw-500 m-0 p14">{time}</p>
        </div>
      </div>
      <div className="d-flex buttons-cover cursor-pointer">
        {name && (
          <div
            className="button d-flex align-items-center justify-content-center w-50 bord-rgt"
            onClick={() => {
              togglePopup(name)
            }}
          >
            <p className=" fw-500 m-0">I'm interested</p>
            <SVGIcon name="navigation/Path" className="arrow" />
          </div>
        )}

        <Link
          to={slug}
          target={CheckLinkDomain(slug) ? "_self" : "_blank"}
          className={`${name ? "w-50" : "w-100"}`}
        >
          <div
            className={`button d-flex align-items-center ${
              name ? "justify-content-center" : ""
            }`}
          >
            <p className="fw-500 m-0">
              {buttonText}
            </p>
            <SVGIcon name="navigation/Path" className="arrow" />
          </div>
        </Link>
      </div>
    </div>
  )
}

export { CardStructure, ShowAllCards }
