const scrollPosition = (targetElement, navbar, margin = 0) => {
  const elementTop = targetElement
    ? document.querySelector(targetElement)?.getBoundingClientRect().top
    : 0
  const navbarHeight = navbar
    ? document.querySelector(navbar)?.getBoundingClientRect().height
    : 0
  return elementTop - navbarHeight - margin
}

const pageScroller = (scrollValue, behavior = "smooth") => {
  window.scrollTo({
    top: scrollValue,
    behavior: behavior,
  })
}

export { pageScroller, scrollPosition }

