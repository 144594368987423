import React, { useEffect, useState } from "react"
import { scrollPosition } from "../utility/scroll-position"

let EventTabs = props => {
  const [scrollValue, setScrollValue] = useState()
  const { allTags } = props
  const mainCategories = ["SuperOps Events", "Other Events"]
  const [screenWidth, setScreenWidth] = useState(0)
  const [mainCategoryDrpDown, setMainCategoryDrpDown] = useState({
    Product: false,
    Thought_Leadership: false,
  })

  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    let arr = [1, 2]
    arr.map(item => {
      const dropdownDiv = document.querySelector(`#sub_tag_${item}`)
      if (dropdownDiv) {
        const toggledNavbar = document
          .querySelector(`#inner_subtag_${item}`)
          ?.getBoundingClientRect()
        dropdownDiv.style.height = `${toggledNavbar.height}px`
      }
    })
  })

  useEffect(() => {
    setScrollValue(
      scrollPosition(
        ".track-category",
        screenWidth < 500 ? ".brand-nav-light-mob" : ".brand-nav-light",
        screenWidth < 500 ? 40 : 140
      )
    )
  }, [screenWidth])

  const MainCategoryDrpDownFn = item => {
    let tempObj = { ...mainCategoryDrpDown }
    if (item === "Product") {
      if (tempObj["Thought_Leadership"]) {
        tempObj["Thought_Leadership"] = false
      }
      tempObj["Product"] = !tempObj["Product"]
    } else {
      if (tempObj["Product"]) {
        tempObj["Product"] = false
      }
      tempObj["Thought_Leadership"] = !tempObj["Thought_Leadership"]
    }
    setMainCategoryDrpDown(tempObj)
  }

  const closeAllDropdowns = () => {
    let tempObj = { ...mainCategoryDrpDown }
    tempObj["Product"] = false
    tempObj["Thought_Leadership"] = false
    setMainCategoryDrpDown(tempObj)
  }

  return (
    <div className="categories horizontal-tabs">
      <div className="taglist-desk p16">
        <div className="alt">
          <div className="d-flex tabs-head">
            {mainCategories.map((item, id) => {
              return (
                <>
                  <div
                    className={`tab-wrapper d-flex justify-content-center align-items-center cursor-pointer ${
                      mainCategoryDrpDown[item.replace(" ", "_")]
                        ? "underline"
                        : ""
                    } ${props.currentTag === item ? "active" : ""}`}
                    onClick={() => {
                      props.selectTag(item)
                      // pageScroller(scrollValue)
                    }}
                  >
                    <p className="font-roboto fw-600 m-0 tab-value">{item}</p>
                  </div>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventTabs
